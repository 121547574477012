import firebase from 'firebase/compat/app';
import { isEmpty, pick } from 'lodash';
import { DateService as dateServiceShared } from '@newmoon-org/shared';

import googleProvider from '@/service/google.provider';
import RolesService, { listenToRole } from '@/service/roles.service';
import SchedulesService from '@/service/schedules.service';
import PermissionService from '@/service/permission.service';

import router from '../../router';
import EmployeesService from '../../service/employees.service';

const VALID_DOMAINS = [
  'flowrightphi.com',
  'flowrightelectric.com',
  'laserremodel.com',
  'monarchlandscapingec.com',
  'socofoxplumbing.com',
  'itps.io',
  'flowrighthomeservices.com',
];

// let unsubscribe = null;

export default {
  loginGoogle: ({ commit }) =>
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then(handleLoginResponse)
      .then(async user => {
        commit('setUser', user);
        await loadAndListenPermissions(commit, user.employee.role);
      })
      .then(() => router.push({ name: 'work-orders' })),

  loginCache: ({ commit }, user) =>
    handleLoginResponse({ user }).then(async user => {
      commit('setUser', user);
      await loadAndListenPermissions(commit, user.employee.role);
    }),

  logout: ({ commit }) =>
    firebase
      .auth()
      .signOut()
      .then(() => {
        commit('setUser', null);
        commit('setPermissions', null);
      })
      .then(() => router.push({ name: 'login' })),

  refreshEmployeeData: ({ commit, state }) =>
    loadEmployee(state.user?.firebaseUser?.email).then(async employee => {
      commit('setUser', { ...state.user, employee });
      await loadAndListenPermissions(commit, employee.role);
    }),

  login: ({ commit }, { email, password }) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(handleLoginResponse)
      .then(async user => {
        commit('setUser', user);
        await loadAndListenPermissions(commit, user.employee.role);
      })
      .then(() => router.push({ name: 'work-orders' }))
      .catch(console.log),
};

function isEmailValid(r) {
  if (!r.user) return false;
  const { email } = r.user;
  if (!email) return false;
  return VALID_DOMAINS.includes(email.split('@')[1]);
}

async function handleLoginResponse(r) {
  if (!isEmailValid(r)) {
    await firebase.auth().signOut();
    throw new Error('Not Valid domain');
  }

  const token = r?.credential?.token;
  if (isEmpty(r.user)) {
    return {
      firebaseUser: r.user,
      token,
      employee: {},
    };
  }

  const employee = await loadEmployee(r.user.email);

  return {
    firebaseUser: r.user,
    employee,
    token,
  };
}

async function loadAndListenPermissions(commit, role) {
  if (!role) return;
  listenToRole(role, async doc => {
    const role = doc.data();
    const permissions = await loadPermissions(role);
    commit('setPermissions', permissions);
  });
}

async function loadPermissions(role) {
  let permissions = [];
  if (role?.permissions) {
    permissions = await PermissionService.getChunkedDBResults(role.permissions);
  }
  return permissions;
}

async function loadEmployee(email) {
  if (!email) return null;

  try {
    const employee = await EmployeesService.getByEmail(email);

    if (!employee) {
      return employee;
    }

    employee.role = await RolesService.getById(employee.role.id);
    employee.schedule = employee.schedule?.id
      ? pick(await SchedulesService.getById(employee.schedule.id), ['id', 'code', 'weeks', 'repeatFromDate', 'status'])
      : employee.schedule;
    employee.getWorkDay = date =>
      dateServiceShared.getWorkDayAsDayjs(dateServiceShared.getWorkDayForDate(employee.schedule, date), date);
    return employee;
  } catch (e) {
    console.error(`Failed to pull meta information, ${e.message}`);
    return null;
  }
}
