import { pick } from 'lodash';
import { CatalogType } from '@newmoon-org/types';

import { init } from '@/service/generic.service';

import store from '@/store';

const methods = init({ collectionPath: 'catalogTypes' });

interface CatalogTypeService {
  dbRef: typeof methods.dbRef;
  get: (id: string) => Promise<CatalogType>;
  getAll: () => Promise<CatalogType[]>;
  getAllActive: () => Promise<CatalogType[]>;
  update: typeof methods.update;
  create: (catalog: Partial<CatalogType>) => Promise<CatalogType>;
}

function create(catalog: Partial<CatalogType>): Promise<CatalogType> {
  catalog.createdAt = new Date();
  const user = store.getters['auth/user'].employee;
  catalog.createdBy = pick(user, ['firstName', 'lastName', 'id', 'workflowFunction', 'code']);
  return methods.create({
    ...catalog,
    value: catalog.name?.replaceAll(/\s/g, ''),
  });
}

const catalogTypeService: CatalogTypeService = {
  dbRef: methods.dbRef,
  get: methods.getById,
  getAll: methods.getAll,
  getAllActive: methods.getAllActive,
  update: methods.update,
  create,
};

export default catalogTypeService;
