<template>
  <div class="flowright-address">
    <div class="columns">
      <div class="column">
        <address-auto-complete v-model="autocompleteAddress" :deleted="deleted"></address-auto-complete>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Street">
          <b-input id="address-street" v-model="street" @input="notify" required :disabled="deleted"></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Address 2">
          <b-input
            id="address-address2"
            v-model="street2"
            @input="notify"
            placeholder="Unit #2"
            :disabled="deleted"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="City">
          <b-input id="address-city" v-model="city" @input="notify" required :disabled="deleted"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="State">
          <b-input id="address-state" v-model="state" @input="notify" required :disabled="deleted"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Zip">
          <b-input id="address-zip" v-model="zip" @input="notify" required :disabled="deleted"></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import AddressAutoComplete from '@/components/address/addressAutoComplete.vue';

export default {
  name: 'FlowrightAddress',
  components: {
    AddressAutoComplete,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    deleted: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      autocompleteAddress: null,
      street: null,
      street2: null,
      city: null,
      state: null,
      zip: null,
    };
  },
  watch: {
    autocompleteAddress: {
      handler() {
        this.street = this.autocompleteAddress.address1;
        this.city = this.autocompleteAddress.locality;
        this.state = this.autocompleteAddress.state;
        this.zip = this.autocompleteAddress.postcode;
        this.notify();
      },
    },
  },
  mounted() {
    this.city = this.value.city;
    this.zip = this.value.zip;
    this.street = this.value.street;
    this.street2 = this.value.address2;
    this.state = this.value.state;
  },
  methods: {
    notify() {
      this.$emit('input', {
        street: this.street,
        street2: this.address2,
        city: this.city,
        state: this.state,
        zip: this.zip,
      });
    },
  },
};
</script>
