<template>
  <div id="workorder-site-history">
    <div class="my-4 is-size-3 header">{{ header }}</div>
    <slot name="jobSitePicker"></slot>
    <div v-if="!jobSiteId || jobSiteId === 'new'">
      <b-message>No Job Site Id</b-message>
    </div>
    <div v-else-if="!workOrderHistory.length">
      <b-message>No Site History</b-message>
    </div>
    <div class="line-items" v-else>
      <list :data="workOrderHistory" :config="{ ...listConfig, listItemClassAdder }"></list>
    </div>
  </div>
</template>

<script>
import SiteService from '@/service/site.service';

import List from '@/components/list/list.vue';

export default {
  name: 'WorkorderSiteHistory',
  components: {
    List,
  },
  props: {
    jobSiteId: {
      type: String,
      default: null,
    },
    header: {
      type: String,
      default: 'Job Site History',
    },
    listItemClassAdder: {
      type: Function,
      default: () => undefined,
    },
  },
  data() {
    return {
      listConfig: {
        labelField: 'workOrderNumber',
        labelLabel: 'WO#',
        paginationPerPage: 10,
        labelOnClick: row => {
          const routeData = this.$router.resolve({
            name: 'manage-work-order',
            params: { workOrderId: row.id },
          });
          window.open(routeData.href, '_blank');
        },
        infoField: row => {
          const hasTechs = row => row.assignedTechs && row.assignedTechs.length;

          const techs = hasTechs(row)
            ? row.assignedTechs.map(t => `${t.firstName.charAt(0).toUpperCase()}. ${t.lastName}`).join(', ')
            : 'No Techs';
          const finishedAt = row.finishedAt ? row.finishedAt.toDate().toLocaleDateString() : 'Unknown Date';
          const customerName = row.customer?.displayAsName || 'No Customer Name Found';

          return `${customerName} -- ${row.jobInfo} -- ${techs} -- ${finishedAt}`;
        },
        infoLabel: 'Info, Techs, Finished At',
      },
    };
  },
  asyncComputed: {
    workOrderHistory: {
      async get() {
        if (!this.jobSiteId) return [];

        const workorders = await SiteService.getWorkOrdersBySite(this.jobSiteId);
        return workorders
          .filter(w => w.id !== this.workOrderId)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      },
      default: [],
    },
  },
};
</script>
