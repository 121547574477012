<template>
  <div id="customer-booking">
    <nav class="breadcrumb">
      <ul>
        <li>
          <router-link class="p-0 m-0" to="/customers"><a>Customers</a></router-link>
        </li>
        <li class="is-active">
          <router-link class="p-0 m-0" to="/customers/booking/${customerId}"><a>Book Work Order</a></router-link>
        </li>
      </ul>
    </nav>
    <div class="header">Book Work Order</div>
    <div class="is-fullwidth" v-if="$asyncComputed.existingWorkOrder.updating">
      <div>Loading Work Order</div>
      <b-progress></b-progress>
    </div>
    <template v-else>
      <b-message v-if="isDeclined" type="is-warning">Declined: {{ workOrder.reasonForDecline }}</b-message>
      <b-message v-else-if="inFinishedState" type="is-info">
        The work order is in a finished state. No edits are allowed.
      </b-message>
      <b-message v-if="originalWorkOrder.id">
        The work order was transferred from WO#
        <a @click="goToOriginalWorkOrder">{{ originalWorkOrder.workOrderNumber }}</a>
        with the given reason: {{ workOrder.reasonForDelay }}.
      </b-message>
      <b-progress v-if="saving"></b-progress>
      <div v-else>
        <div class="created-by" v-if="!isNew">
          Created By: {{ createdBy.firstName }}
          {{ createdBy.lastName }}
        </div>
        <job-site-modal :is-open="showJobSiteModal" @close="showJobSiteModal = false" @save="addNewJobSite" />
        <b-modal
          v-model="showActivityModal"
          trap-focus
          :full-screen="isMobile"
          aria-role="dialog"
          aria-modal
          :destroy-on-hide="true"
        >
          <template #default>
            <div class="modal-card">
              <header class="has-background-danger has-text-white modal-card-head">Detected Inactivity</header>
              <section class="modal-card-body">
                <p v-if="countDownNumber === 0">All assigned technicians were unassigned due to inactivity!</p>
                <p v-else>
                  All assigned technicians will be unassigned due to inactivity in {{ countDownNumber }} second(s)...
                </p>
              </section>
            </div>
          </template>
        </b-modal>
        <b-modal
          v-model="isAuthContactAddNewActive"
          trap-focus
          :full-screen="isMobile"
          aria-role="dialog"
          aria-modal
          :destroy-on-hide="false"
        >
          <template #default>
            <form class="modal-card" @submit.prevent="addNewAuthContact">
              <header class="modal-card-head">Add new Authorized Contact</header>
              <section class="modal-card-body">
                <b-field label="Name">
                  <b-input v-model="newAuthContact.name" :loading="addingAuthContact" expanded></b-input>
                </b-field>
                <b-field label="Email">
                  <b-input v-model="newAuthContact.email" :loading="addingAuthContact" expanded></b-input>
                </b-field>
                <b-field label="Phone Number">
                  <b-input v-model="newAuthContact.phone" :loading="addingAuthContact" expanded></b-input>
                </b-field>
              </section>
              <footer class="modal-card-foot">
                <b-button
                  size="is-small"
                  label="Close"
                  type="is-danger"
                  @click="isAuthContactAddNewActive = false"
                ></b-button>
                <b-button
                  native-type="submit"
                  size="is-small"
                  label="Add"
                  type="is-success"
                  :disabled="!newAuthContactIsValid"
                ></b-button>
              </footer>
            </form>
          </template>
        </b-modal>
        <b-modal
          v-model="isApprovalActive"
          trap-focus
          :full-screen="isMobile"
          aria-role="dialog"
          aria-modal
          :destroy-on-hide="false"
        >
          <template #default>
            <form class="modal-card" @submit.prevent="confirmApproval">
              <header class="modal-card-head">Approve Booking</header>
              <section class="modal-card-body">
                <div class="columns title is-6">
                  <div class="column is-fullwidth">
                    <span>
                      You are booking {{ techsWithOvertimeString }} outside their normal work hours. Please make sure to
                      confirm with the technician(s) and their manager before clicking submit. I understand and confirm!
                      Your employee code is
                    </span>
                    <span class="has-text-danger-dark">&nbsp;{{ employeeCode }}</span>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Employee Code">
                      <b-input v-model="workOrder.approvedBy" expanded></b-input>
                    </b-field>
                  </div>
                </div>
              </section>
              <footer class="modal-card-foot">
                <b-button size="is-small" label="Close" type="is-danger" @click="isApprovalActive = false"></b-button>
                <b-button
                  native-type="submit"
                  size="is-small"
                  label="Submit"
                  type="is-success"
                  :disabled="!workOrder.approvedBy || !typedValidEmployeeCode"
                ></b-button>
              </footer>
            </form>
          </template>
        </b-modal>
        <div class="columns is-mobile">
          <div class="column">
            <b-field>
              <b-switch v-model="workOrder.isPrevailingWage" :disabled="inFinishedState">Prevailing Wage</b-switch>
            </b-field>
          </div>
        </div>
        <div class="columns customer">
          <div class="column is-fullwidth">
            <div class="mb-2 is-size-3" v-if="customer.id">Customer: {{ customer.name }}</div>
            <b-skeleton size="is-large" :active="loadingCustomer"></b-skeleton>
            <div class="columns" v-if="customer.id">
              <div class="column">
                <div class="customer-field">
                  <span class="field-name">Contact</span>
                  <span class="field-value">{{ contact.name }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Phone</span>
                  <span class="field-value">{{ contact.phone }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Email</span>
                  <span class="field-value">{{ contact.email || 'No Email' }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Address</span>
                  <span class="field-value">{{ address }}</span>
                </div>
              </div>
              <div class="column">
                <div class="customer-field">
                  <span class="field-name">Account Status</span>
                  <span class="field-value">{{ customer.accountingStatus || 'None' }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Discount Status</span>
                  <span class="field-value">{{ customer.discountStatus || 'None' }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">EZ-Care</span>
                  <span class="field-value">{{ currentEzCare }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">EZ-Care Date</span>
                  <span class="field-value">{{ currentJobSite.ezCareStartDate | formatDate('N/A') }}</span>
                </div>
              </div>
            </div>
            <b-skeleton size="is-large" :active="loadingCustomer"></b-skeleton>
            <div class="card-footer">
              <div class="card-footer-item">
                <router-link :to="{ name: 'customer-edit', params: { customerId: customer.id } }" target="_blank">
                  Edit
                  <b-icon class="ml-1" icon="external-link-alt"></b-icon>
                </router-link>
                <b-skeleton size="is-large" :active="loadingCustomer"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
        <form @submit.prevent="confirm">
          <div class="is-flex is-flex-direction-row is-flex-grow-1 mb-5">
            <b-field label="Referred By">
              <b-dropdown
                v-model="workOrder.referredBy"
                scrollable
                multiple
                aria-role="list"
                :disabled="$asyncComputed.referredByList.updating || loadingSchedulesPoller"
              >
                <template #trigger>
                  <b-button
                    :icon-right="loadingSchedulesPoller ? 'spinner' : 'caret-down'"
                    :disabled="$asyncComputed.referredByList.updating"
                  >
                    Selected ({{ workOrder.referredBy ? workOrder.referredBy.length : 0 }})
                  </b-button>
                </template>
                <b-dropdown-item
                  v-for="referredBy in referredByList"
                  :value="referredBy"
                  :key="referredBy"
                  aria-role="listitem"
                  :disabled="$asyncComputed.referredByList.updating"
                >
                  <span>{{ referredBy }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
            <b-taglist v-if="workOrder.referredBy" class="ml-5">
              <b-tag v-for="(referredBy, i) in workOrder.referredBy" :key="i" type="is-info" size="is-medium">
                {{ referredBy }}
              </b-tag>
            </b-taglist>
          </div>
          <div class="is-flex is-flex-direction-column is-flex-grow-1">
            <b-field grouped>
              <b-field label="Job Site" expanded>
                <b-autocomplete
                  :data="filteredJobSiteData"
                  field="address"
                  :loading="loadingCustomer"
                  v-model="selectedJobSiteAddress"
                  @select="jobSiteChanged"
                  clearable
                  open-on-focus
                  :expanded="true"
                ></b-autocomplete>
                <b-button
                  class="is-success"
                  label="Add New"
                  @click="showJobSiteModal = true"
                  :disabled="inFinishedState"
                ></b-button>
              </b-field>
            </b-field>
            <distance-to-office
              v-if="workOrder.jobSite.address"
              :address="workOrder.jobSite.address"
            ></distance-to-office>
            <b-field label="Service Zone">
              <b-autocomplete
                v-model="serviceZoneName"
                :data="serviceZones"
                field="displayName"
                :loading="$asyncComputed.serviceZones.updating"
                :open-on-focus="true"
                @select="c => onSelectedServiceZone(c)"
                :disabled="inFinishedState"
                required
              ></b-autocomplete>
            </b-field>
            <div v-if="!workOrder.isTaxOverride" class="is-flex is-flex-direction-row">
              <b-field class="mr-2 is-flex-grow-1" label="Tax Rate %">
                <b-input
                  v-model="taxRatePercentage"
                  step="0.01"
                  type="number"
                  :disabled="loadingTaxInfo || inFinishedState"
                  :loading="loadingTaxInfo"
                  required
                ></b-input>
              </b-field>
              <b-field class="is-flex-grow-1" label="Tax Jurisdiction">
                <b-input
                  v-model="workOrder.taxJurisdictionCode"
                  type="text"
                  :disabled="loadingTaxInfo || inFinishedState"
                  :loading="loadingTaxInfo"
                  required
                ></b-input>
              </b-field>
            </div>
            <div v-else class="is-flex is-flex-direction-row mb-4">
              <b-notification type="is-info is-light" class="is-flex-grow-1" :closable="false">
                Tax override is applied...
              </b-notification>
            </div>
            <b-field label="Anticipated Method of Payment">
              <b-select v-model="workOrder.methodOfPayment" :disabled="inFinishedState" expanded required>
                <option v-for="paymentMethod in paymentMethods" :value="paymentMethod.value" :key="paymentMethod.value">
                  {{ paymentMethod.label }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="my-3 is-size-3">Authorization Contact</div>
          <div class="columns">
            <div class="column">
              <b-field grouped>
                <b-field label="Authorized Person" expanded>
                  <b-select v-model="workOrder.authorizationContact" :disabled="inFinishedState" expanded required>
                    <option v-for="c in contacts" :value="c" :key="c.id">{{ c.name }}</option>
                  </b-select>
                  <b-button
                    class="is-success"
                    label="Add New"
                    @click="isAuthContactAddNewActive = true"
                    :disabled="inFinishedState"
                  ></b-button>
                </b-field>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Relation to Property">
                <b-select
                  v-model="workOrder.authorizationContact.propertyRelation"
                  :disabled="inFinishedState"
                  expanded
                  required
                >
                  <option
                    v-for="propertyRelation in propertyRelations"
                    :value="propertyRelation.value"
                    :key="propertyRelation.label"
                  >
                    {{ propertyRelation.label }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="columns" v-if="workOrder.authorizationContact">
            <div class="column">
              <div class="is-size-6">
                <div class="customer-field">
                  <span class="field-name">Name</span>
                  <span class="field-value">{{ workOrder.authorizationContact.name }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Phone</span>
                  <span class="field-value">{{ workOrder.authorizationContact.phone }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Email</span>
                  <span class="field-value">{{ workOrder.authorizationContact.email || 'No Email' }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Billing Address</span>
                  <span class="field-value">{{ workOrder.authorizationContact.address }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="my-4 is-size-3">Type of Problem</div>
          <div class="columns">
            <div class="column">
              <b-field label="Work Order Type" expanded>
                <b-select v-model="workOrder.catalogTypeId" :disabled="inFinishedState" expanded required>
                  <option v-for="t in workOrderTypes" :value="t.id" :key="t.id">{{ t.name }}</option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Company" expanded>
                <b-select v-model="workOrder.company" :disabled="inFinishedState" expanded required>
                  <option v-for="c in filteredCompanies" :value="c.code" :key="c.code">
                    {{ c.code }} - {{ c.company }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Appointment Reason" expanded>
                <b-select v-model="workOrder.appointmentReason" :disabled="inFinishedState" expanded required>
                  <option v-for="a in appointmentReasons" :value="a.value" :key="a.name">{{ a.name }}</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4">
              <b-field>
                <b-switch v-model="workOrder.isEmergency" :disabled="inFinishedState || workOrder.isOnCall">
                  Emergency
                </b-switch>
              </b-field>
            </div>
            <div class="column is-4">
              <b-switch v-model="workOrder.isHealthAndSafety" :disabled="inFinishedState">Health &amp; Safety</b-switch>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <warranty-possible
                :job-site-id="workOrder.jobSite.id"
                v-model="workOrder.warrantyInformation"
              ></warranty-possible>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <work-order-products
                :work-order="workOrder"
                :show-product-price="true"
                :is-editable="true"
                :is-addable="!!workOrder.catalogTypeId"
                @productUpdate="onProductChange"
                :catalog="actualWorkOrderType?.name"
                :disabled="inFinishedState"
              ></work-order-products>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Job Info">
                <b-input
                  type="textarea"
                  v-model="workOrder.jobInfo"
                  validation-message="Must have at least 4 characters"
                  :disabled="inFinishedState"
                  minlength="4"
                  required
                ></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Notes to Tech">
                <b-input
                  type="textarea"
                  v-model="workOrder.techNotes"
                  validation-message="Must have at least 4 characters"
                  :disabled="inFinishedState"
                  minlength="4"
                  required
                ></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns" v-if="workOrder.reasonForDecline">
            <div class="column">
              <b-field label="Reason For Decline">
                <b-input type="textarea" v-model="workOrder.reasonForDecline" :disabled="inFinishedState"></b-input>
              </b-field>
            </div>
          </div>
          <div class="my-4 is-size-3">Date & Assignment</div>
          <div class="columns" v-if="isAfterEnd">
            <div class="column">
              <b-message type="is-warning">
                <span class="mr-1">
                  Work Order is past the ending time. If you need to edit Date and Assignment Delay work order.
                </span>
                <router-link :to="{ name: 'manage-work-order', params: { workOrderId: workOrderId } }" target="_blank">
                  Manage Work Order
                </router-link>
              </b-message>
            </div>
          </div>
          <div class="columns">
            <div class="column is-2">
              <b-field>
                <b-switch v-model="workOrder.waitlisted" :disabled="dateAndAssignmentDisabled">
                  Add To Waitlist
                </b-switch>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field>
                <b-switch v-model="applyAreaFilter">Apply Product Area Filter</b-switch>
              </b-field>
            </div>
            <div class="column is-8 has-text-right">
              <b-button
                :label="showTechAvailability ? 'Hide Tech Availability' : 'Show Tech Availability'"
                type="is-info"
                size="is-small"
                v-if="!dateAndAssignmentDisabled"
                @click="showTechAvailability = !showTechAvailability"
              ></b-button>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-collapse v-model="showTechAvailability">
                <div class="availability">
                  <gantt-chart
                    :key="`${workOrder.company}-${workOrder.catalogTypeId}`"
                    :start-date="workOrder.date"
                    :search-options="[{ key: 'skill', text: 'Skill' }]"
                    :custom-preapplied-filter="searchTechsBySkills"
                    :on-row-click-primary="assignToPrimaryTech"
                    :on-row-click-secondary="assignToSecondaryTech"
                    primary-action-text="Assign primary"
                    secondary-action-text="Assign secondary"
                  ></gantt-chart>
                </div>
              </b-collapse>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-half">
              <b-field label="Appointment Type" expanded>
                <b-select v-model="workOrder.appointmentType" expanded :disabled="dateAndAssignmentDisabled">
                  <option
                    v-for="appointmentType in appointmentTypes"
                    :value="appointmentType.value"
                    :key="appointmentType.value"
                  >
                    {{ appointmentType.label }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field label="Work Date">
                <holiday-datepicker
                  is-date-time
                  class="is-fullwidth"
                  expanded
                  v-model="workOrder.date"
                  icon="calendar"
                  :min-datetime="new Date()"
                  @input="dateChanged"
                  editable
                  :disabled="dateAndAssignmentDisabled"
                  placeholder="Click to select..."
                  :timepicker="{ incrementMinutes: 5 }"
                ></holiday-datepicker>
                <b-button v-if="loggedInUserIsTech" class="is-success is-pulled-right" @click="setSuggestedStartDate">
                  Next Available
                </b-button>
              </b-field>
            </div>
            <div class="column is-one-quarter">
              <b-field label="Additional Duration (Hr)">
                <b-input
                  v-model="workOrder.additionalDuration"
                  step="0.1"
                  type="number"
                  :disabled="dateAndAssignmentDisabled"
                  expanded
                ></b-input>
              </b-field>
            </div>
            <div class="column is-one-quarter">
              <b-field label="Travel Time (Hr)">
                <b-input
                  v-model="workOrder.serviceZoneOverride"
                  step="0.1"
                  min="0"
                  type="number"
                  :disabled="dateAndAssignmentDisabled"
                  expanded
                ></b-input>
              </b-field>
            </div>
            <div class="column is-one-quarter">
              <b-field label="Product Duration (Hr)">
                <b-input
                  v-model="workOrder.productTimeOverride"
                  step="0.1"
                  min="0"
                  type="number"
                  :disabled="dateAndAssignmentDisabled"
                  expanded
                ></b-input>
              </b-field>
            </div>
            <div class="column is-one-quarter">
              <b-field label="Total Duration (Hr)">
                <b-input v-model="workOrder.duration" type="number" disabled expanded></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field label="Assigned Primary Technician" expanded>
                <b-select
                  v-model="primaryTech"
                  placeholder="Select"
                  :disabled="dateAndAssignmentDisabled"
                  :loading="loadingSchedulesPoller"
                  expanded
                >
                  <option :value="null">Select</option>
                  <option v-for="tech in primaryTechs" :value="tech.id" :key="tech.id">
                    {{ tech.lastName }} {{ tech.firstName }}{{ namePostfixes(tech) }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field label="Assigned Secondary Technician" expanded>
                <b-select
                  v-model="secondaryTech"
                  placeholder="Select"
                  :disabled="dateAndAssignmentDisabled"
                  :loading="loadingSchedulesPoller"
                  expanded
                >
                  <option :value="null">Select</option>
                  <option v-for="tech in secondaryTechs" :value="tech.id" :key="`${tech.id}-secondary`">
                    {{ tech.lastName }} {{ tech.firstName }}{{ namePostfixes(tech) }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-half" v-if="primaryTech">
              <b-field label="Tech Project ID Override">
                <b-input v-model="techProjectIdOverride" :disabled="dateAndAssignmentDisabled" type="text"></b-input>
              </b-field>
            </div>
            <div class="column is-half" v-if="primaryTech">
              <b-tag type="is-info" size="is-large">Current Project: {{ techProjectId }}</b-tag>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <comments-view
                :association-id="customerId"
                :comment-type="commentType"
                :displayable-types="commentDisplayableTypes"
                title="Customer Comments"
              ></comments-view>
            </div>
          </div>
          <div class="mt-2 columns is-pulled-right">
            <div class="column">
              <div class="buttons">
                <b-button @click="cancel">Back</b-button>
                <customer-booking-decline-modal
                  class="mr-2"
                  :disabled="inFinishedState"
                  @decline="decline"
                ></customer-booking-decline-modal>
                <b-button class="is-danger" v-if="userCanDelete && !isNew" @click="confirmDeleteWorkOrder">
                  Delete
                </b-button>
                <b-tooltip v-if="!isValid" :label="currentErrors" position="is-left" multilined>
                  <b-button class="is-success" disabled>{{ isNew ? 'Submit' : 'Update' }}</b-button>
                </b-tooltip>
                <button
                  class="button is-success"
                  v-else
                  type="submit"
                  :disabled="inFinishedState || loadingSchedulesPoller"
                >
                  {{ isNew ? 'Submit' : 'Update' }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </template>
    <workorder-site-history
      v-if="$asyncComputed.existingWorkOrder.success"
      :job-site-id="workOrder.jobSite.id"
      class="mt-20"
    ></workorder-site-history>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  debounce,
  difference,
  filter,
  first,
  get,
  identity,
  isEmpty,
  isEqual,
  keyBy,
  last,
  map,
  pick,
  sortBy,
  uniq,
  uniqWith,
} from 'lodash';
import md5 from 'md5';
import { DateService as dateServiceShared, CommentType } from '@newmoon-org/shared';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters } from 'vuex';

import CatalogService from '@/service/catalog.service';
import CustomerService, { EZ_CARE_TYPES } from '@/service/customer.service';
import EmployeesService from '@/service/employees.service';
import TaxService from '@/service/tax.service';
import WorkOrderService from '@/service/workorders.service';
import CommonListsService from '@/service/common-lists.service';
import { getByCustomerId, create as createJobSite } from '@/service/jobSite.service';

import DistanceToOffice from '@/components/DistanceToOffice.vue';
import HolidayDatepicker from '@/components/HolidayDatepicker.vue';
import GanttChart from '@/components/timeCommitment/ganttchart/GanttChart.vue';
import CustomerBookingDeclineModal from '@/components/workorders/CustomerBookingDeclineModal.vue';
import WarrantyPossible from '@/components/workorders/WarrantyPossible.vue';
import WorkOrderProducts from '@/components/workorders/WorkOrderProducts.vue';
import WorkorderSiteHistory from '@/components/workorders/WorkorderSiteHistory.vue';
import JobSiteModal from '@/components/work/JobSiteModal.vue';

import CommentsView from '@/pages/CommentsView.vue';
import customerMixin from '@/mixins/customer';
import schedulerMixin from '@/mixins/scheduler';
import userActivityMixin from '@/mixins/userActivity';
import visibilityMixin from '@/mixins/visibility';
import workorderPageActionsMixin from '@/mixins/workorderPageActions';

export default {
  name: 'CustomerBookingPage',
  components: {
    DistanceToOffice,
    CustomerBookingDeclineModal,
    WarrantyPossible,
    CommentsView,
    WorkOrderProducts,
    WorkorderSiteHistory,
    GanttChart,
    HolidayDatepicker,
    JobSiteModal,
  },
  mixins: [customerMixin, schedulerMixin, workorderPageActionsMixin, userActivityMixin, visibilityMixin],
  data() {
    return {
      ezCareTypes: [...EZ_CARE_TYPES],
      applyAreaFilter: false,
      suggestionIndex: 0,
      suggestedStartDates: [],
      commentType: CommentType.CUSTOMER,
      commentDisplayableTypes: [CommentType.CUSTOMER],
      pageActionsSnapshotId: null,
      selectedJobSiteAddress: null,
      techProjectIdOverride: null,
      loadingTaxInfo: false,
      taxRatePercentage: 0,
      isApprovalActive: false,
      serviceZoneName: null,
      countDown: debounce(() => {
        if (this.countDownNumber > 0) {
          this.countDownNumber--;
        } else {
          this.primaryTech = null;
          this.secondaryTech = null;
        }
      }, 1000),
      countDownNumber: 0,
      showActivityModal: false,
      pingCancelled: false,
      saving: false,
      showJobSiteModal: false,
      addingJobSite: false,
      newJobSite: {
        street: null,
        city: null,
        state: null,
        zip: null,
      },
      newJobSiteEzCare: {
        ezCare: null,
        ezCareStartDate: null,
      },
      newAuthContact: {
        name: null,
        email: null,
        phone: null,
      },
      paymentMethods: [
        { value: 'cash', label: 'Cash' },
        { value: 'card', label: 'Card' },
        { value: 'check', label: 'Check' },
        { value: 'billOut', label: 'Bill Out (Commercial Only)' },
        { value: 'ccInSafe', label: 'CC in Safe' },
      ],
      propertyRelations: [
        { value: 'owner', label: 'Owner' },
        { value: 'landlord', label: 'Landlord' },
        { value: 'propertyManager', label: 'Property Manager' },
      ],
      appointmentTypes: [
        { value: 'pending-parts', label: 'Unnassigned - Pending Parts' },
        { value: 'waitlist', label: 'Unnassigned - Waitlist' },
        { value: 'assigned', label: 'Assigned' },
      ],
      isCustomerEditActive: false,
      isAuthContactAddNewActive: false,
      addingAuthContact: false,
      originalApprovedBy: null,
      workOrder: {
        referredBy: [],
        isPrevailingWage: false,
        authorizationContact: {
          propertyRelation: 'owner',
          name: '',
          phone: '',
        },
        customer: {
          id: null,
        },
        serviceZone: {
          id: null,
        },
        techProjectId: null,
        additionalDuration: 0,
        methodOfPayment: null,
        taxRate: 0.0,
        isEmergency: false,
        isOnCall: false,
        company: '01',
        customerDescription: '',
        techNotes: '',
        approvedBy: null,
        approvalHash: null,
        type: null,
        catalogTypeId: null,
        appointmentReason: 'resPlumbReq',
        appointmentType: 'assigned',
        jobSite: {},
        date: this.getStartDate(),
        duration: 1,
        waitlisted: false,
        lineItems: [],
        status: 'new',
        serviceZoneOverride: 0,
        productTimeOverride: null,
        isTaxOverride: false,
      },
      primaryTech: null,
      secondaryTech: null,
      customerIsAuthorized: true,
      allTechs: [],
      pingFunction: debounce(this.updateAction, 60000),
      showTechAvailability: false,
    };
  },
  computed: {
    ...mapGetters('centralScheduler', ['activeEventsModel']),
    productAreas() {
      return uniq(filter(this.workOrder?.lineItems?.map(it => it?.product?.productArea?.id)));
    },
    activeEvents() {
      return this.activeEventsModel?.events;
    },
    loggedInUserWorkDay() {
      return this.loggedInUser.employee.getWorkDay(this.workOrder.date);
    },
    currentEzCare() {
      if (this.currentJobSite?.ezCare === '') {
        return 'None';
      }
      return EZ_CARE_TYPES.find(it => it.value === (this.currentJobSite?.ezCare?.toLowerCase() ?? null)).label;
    },
    currentJobSite() {
      return get(this.jobSitesMap, this.workOrder.jobSite?.id, {});
    },
    jobSitesMap() {
      return keyBy(this.jobSites, 'id');
    },
    filteredJobSiteData() {
      return this.jobSites ? this.jobSites : [];
    },
    workOrderStates() {
      return WorkOrderService.WORK_ORDER_STATES;
    },
    isDeclined() {
      return this.workOrder.status.includes('decline');
    },
    inFinishedState() {
      return this.workOrder.state === this.workOrderStates.FINISHED && this.workOrder.status !== 'finalized';
    },
    isAfterEnd() {
      if (!this.workOrder?.date || this.isNew) return false;
      return dayjs().isAfter(this.workOrder.endDate);
    },
    dateAndAssignmentDisabled() {
      return this.inFinishedState || this.isAfterEnd;
    },
    techProjectId() {
      if (this.workOrder.parentWasDelayed) {
        return this.workOrder.techProjectId;
      }

      if (!this.primaryTech) {
        return null;
      }

      const primaryTech = this.availableTechsMap[this.primaryTech] ?? {};
      return WorkOrderService.buildTechProjectId(primaryTech, this.workOrder.date);
    },
    approvalStringRaw() {
      return [
        ...this.techsWithOvertime.map(it => it.id),
        this.workOrder.startDate?.valueOf(),
        this.workOrder.endDate?.valueOf(),
      ]
        .filter(it => it)
        .sort()
        .join(',');
    },
    approvalHash() {
      return this.approvalStringRaw ? md5(this.approvalStringRaw) : null;
    },
    currentErrors() {
      return this.lineItemsError;
    },
    atLeastOneLineItemPresent() {
      return this.workOrder.lineItems?.length > 0;
    },
    lineItemsError() {
      return !this.atLeastOneLineItemPresent ? 'Must have at least 1 line item added.' : null;
    },
    isValid() {
      return this.atLeastOneLineItemPresent;
    },
    employeeCode() {
      return (
        this.loggedInUser.employee.code ??
        `${first(this.loggedInUser.employee.firstName) ?? ''}${this.loggedInUser.employee.lastName}`.toLowerCase()
      );
    },
    typedValidEmployeeCode() {
      return this.employeeCode === this.workOrder.approvedBy;
    },
    isNew() {
      return this.workOrderId === 'new';
    },
    loggedInFirstName() {
      return this.loggedInUser?.employee?.firstName;
    },
    loggedInLastName() {
      return this.loggedInUser?.employee?.lastName;
    },
    loggedInUserName() {
      return `${this.loggedInLastName} ${this.loggedInFirstName}`;
    },
    approvalHashesMatch() {
      return this.approvalHash === this.workOrder.approvalHash;
    },
    requiresApproval() {
      return !isEmpty(this.techsWithOvertime) && !this.approvalHashesMatch;
    },
    techsWithOvertimeString() {
      return this.techsWithOvertime?.map(it => it.name)?.join(', ');
    },
    techsWithOvertime() {
      return (
        this.assignedTechs
          ?.filter(it => this.techScheduleQualifications[it]?.requiresOvertime)
          ?.map(it => ({
            id: it,
            name: `${this.availableTechsMap[it]?.lastName ?? 'Unknown'} ${this.availableTechsMap[it]?.firstName ?? ''}`,
          })) ?? []
      );
    },
    availableTechsMap() {
      return keyBy(this.availableTechs, 'id');
    },
    assignedTechs() {
      return [this.primaryTech, this.secondaryTech].filter(it => it);
    },
    techScheduleQualifications() {
      return (
        this.allTechs.reduce(
          (acc, tech) => ({
            ...acc,
            [tech.id]: dateServiceShared.scheduleIsQualified(
              this.scheduleDefinitions[tech.schedule?.id],
              this.workOrder.startDate,
              this.workOrder.endDate
            ),
          }),
          {}
        ) ?? {}
      );
    },
    availableTechs() {
      const shouldNotSkip = it =>
        !this.employeesToSkip.includes(it.id) &&
        !!it.skillIds?.includes(this.workOrder.catalogTypeId) &&
        !!this.techScheduleQualifications[it.id]?.isQualified;

      return this.allTechs.filter(shouldNotSkip);
    },
    employeesToSkip() {
      return uniqWith([...this.affectedEmployees, ...this.conflictingBookingsAssignedTechs], isEqual);
    },
    productDuration() {
      return get(this.workOrder, 'lineItems', []).reduce(
        (acc, li) => Number(li.product.duration ?? 0) * (li.quantity ?? 1) + acc,
        0
      );
    },
    isMobile() {
      return this.$isMobile();
    },
    newJobSiteIsValid() {
      return (
        this.newJobSite.city &&
        this.newJobSite.state &&
        this.newJobSite.zip &&
        this.newJobSite.street &&
        (!this.newJobSiteEzCare.ezCare || !!this.newJobSiteEzCare.ezCareStartDate)
      );
    },
    newAuthContactIsValid() {
      return this.newAuthContact.name && this.newAuthContact.email && this.newAuthContact.phone;
    },
    secondaryTechs() {
      return this.availableTechs.filter(it => it.id !== this.primaryTech);
    },
    primaryTechs() {
      return this.availableTechs.filter(
        it =>
          it.id !== this.secondaryTech &&
          (!this.loggedInUserIsTech || this.loggedInUser.employee.id === it.id) &&
          (!this.applyAreaFilter || difference(this.productAreas, it.productAreas?.ids).length === 0)
      );
    },
    customerId() {
      return this.$route.params.customerId;
    },
    contact() {
      if (this.customer?.contacts?.length) {
        return this.customer.contacts[0];
      }
      return {};
    },
    contacts() {
      return this.customer?.contacts;
    },
    address() {
      return this.customer?.billing?.address ?? '';
    },
    searchQuery() {
      return this.$route.query.searchQuery;
    },
    workOrderId() {
      return this.$route.params.workOrderId;
    },
    atLeastOneTechWasAssigned() {
      return !!this.primaryTech || !!this.secondaryTech;
    },
    actualWorkOrderType() {
      return this.workOrderTypesMap[this.workOrder.catalogTypeId] ?? null;
    },
    workOrderTypesMap() {
      return keyBy(this.workOrderTypes, 'id') ?? {};
    },
    filteredCompanies() {
      if (!this.workOrderTypes) return this.companyOptions;
      if (!this.workOrder.catalogTypeId) return this.companyOptions;
      const actualType = this.workOrderTypesMap[this.workOrder.catalogTypeId];
      if (!actualType || !actualType.companies) return this.companyOptions;
      const theCompanies = this.companyOptions.filter(company => actualType.companies.includes(company.id));
      if (!theCompanies) {
        console.error('No Companies Found in the dropdown to display');
        return this.companyOptions;
      }
      return theCompanies;
    },
    userCanDelete() {
      return ['global administrator', 'administrator'].includes(
        this.loggedInUser?.employee?.workflowFunction?.name?.toLowerCase()
      );
    },
  },
  asyncComputed: {
    referredByList: {
      async get() {
        try {
          const employees = (await EmployeesService.getAllActive()).map(
            it => `${it.lastName} ${it.firstName} (${it.code ?? 'N/A'})`
          );
          const customRefs = (await CommonListsService.getById('referredBy'))?.list ?? [];
          return sortBy([...employees, ...customRefs]);
        } catch (e) {
          console.error(`Failed to pull references, ${e}`);
          return [];
        }
      },
      default: [],
    },
    originalWorkOrder: {
      get() {
        return this.existingWorkOrder?.parentWorkOrderId
          ? WorkOrderService.getById(this.existingWorkOrder.parentWorkOrderId).then(wo =>
              pick(wo, ['id', 'workOrderNumber', 'status', 'state'])
            )
          : {};
      },
      default: {},
    },
    existingWorkOrder: {
      get() {
        return !this.isNew
          ? WorkOrderService.getById(this.workOrderId).then(async workOrder => {
              if (!workOrder.id) {
                this.$router.go(-1);
                return;
              }

              const projectId = workOrder.projectId || workOrder.project?.id;
              if (projectId) {
                await this.$router.push({
                  name: 'project-work-order-edit',
                  params: {
                    projectId,
                    workOrderId: this.workOrderId,
                  },
                });
                return;
              }
              if (workOrder.jobSite.id) {
                this.selectedJobSiteAddress = workOrder.jobSite.address;
              }

              const serviceZone = await CatalogService.getById(workOrder.serviceZone?.id).then(r => ({
                ...r,
                displayName: `${r.name} (${r.duration || 0} hrs)`,
              }));
              this.workOrder = { ...this.workOrder, ...workOrder, serviceZone };
              this.primaryTech = this.workOrder.assignedTechs?.find(it => !!it.isPrimary)?.id;
              this.secondaryTech = this.workOrder.assignedTechs?.find(it => !it.isPrimary)?.id;
              this.serviceZoneName = `${serviceZone.name} (${serviceZone.duration} hrs)`;
              this.workOrder.serviceZoneOverride = this.workOrder.serviceZoneOverride ?? serviceZone.duration ?? 0;
              this.workOrder.productTimeOverride = this.workOrder.productTimeOverride ?? this.productDuration;
              this.taxRatePercentage = this.workOrder.taxRate * 100;
              this.originalApprovedBy = this.workOrder.approvedBy;
              return workOrder;
            })
          : {};
      },
      default: {},
    },
    createdBy: {
      get() {
        if (!this.workOrder?.createdBy) {
          return Promise.resolve({ firstName: 'Unknown', lastName: 'Unknown' });
        }

        return EmployeesService.get(get(this.workOrder.createdBy, 'id', this.workOrder.createdBy));
      },
      default: {},
    },
    workOrderTypes() {
      const skills = this.loggedInUserIsTech ? new Set(this.loggedInUser.employee.skillIds) : [];
      return CatalogService.getWOTypes().then(it => (this.loggedInUserIsTech ? it.filter(c => skills.has(c.id)) : it));
    },
    appointmentReasons() {
      return WorkOrderService.getReasons();
    },
    companyOptions: {
      async get() {
        return WorkOrderService.woCompanies();
      },
      default: [],
    },
    serviceZones: {
      get() {
        return CatalogService.list({
          search: this.serviceZoneName,
          pageSize: 200,
          searchFilter: 'specification:service-zone AND status:active',
        })
          .then(({ data }) =>
            data.map(it => ({
              ...it,
              displayName: `${it.name} (${it.duration || 0} hrs)`,
            }))
          )
          .catch(e => {
            console.error('Failed to list catalog, ', e.message);
            return [];
          });
      },
      default: [],
    },
    jobSites: {
      get() {
        return getByCustomerId(this.customerId);
      },
      default: [],
    },
  },
  watch: {
    activeEvents: {
      handler(value, old) {
        if (!isEqual(value, old)) {
          this.fillInSuggestedStartDates();
        }
      },
    },
    workOrderTypes: {
      handler(value) {
        this.workOrder.catalogTypeId =
          this.workOrder.catalogTypeId ?? value.length === 1
            ? first(value).id
            : keyBy(value, 'value')[this.workOrder.type]?.id ?? null;
      },
    },
    taxRatePercentage: {
      immediate: true,
      handler() {
        this.workOrder.taxRate = this.taxRatePercentage / 100;
      },
    },
    approvalHashesMatch: {
      handler(value) {
        this.workOrder.approvedBy = value ? this.originalApprovedBy : null;
      },
    },
    userIsActive: {
      handler(value) {
        this.showActivityModal = !value && this.atLeastOneTechWasAssigned;
        this.countDownNumber = !this.showActivityModal ? 0 : 30;
        this.countDown.cancel();
      },
    },
    countDownNumber: {
      handler() {
        if (this.showActivityModal) {
          this.countDown();
        }
      },
    },
    'workOrder.additionalDuration'() {
      this.calculateWorkOrderDuration();
    },
    'workOrder.productTimeOverride'() {
      this.calculateWorkOrderDuration();
    },
    'workOrder.serviceZoneOverride'() {
      this.calculateWorkOrderDuration();
    },
    'workOrder.jobSite': {
      handler(value, old) {
        if (!isEqual(value, old)) {
          this.getTaxRate(this.workOrder.jobSite?.address);
        }
      },
    },
    customer: {
      handler(value) {
        if (value?.id) {
          this.workOrder.authorizationContact = get(value, 'contacts[0]', {});
          this.workOrder.authorizationContact.propertyRelation =
            this.workOrder.authorizationContact.propertyRelation || 'owner';
          this.workOrder.customer = {
            id: this.customerId,
            displayAsName: value.displayAsName,
          };
        }

        this.workOrder.jobSite = !isEmpty(this.workOrder.jobSite) ? this.workOrder.jobSite : {};

        if (!get(this.workOrder.jobSite, 'id', null)) {
          this.workOrder.jobSite = value?.jobSites?.find(it => it.address === this.workOrder.jobSite) ?? {};
        }
      },
    },
    affectedEmployees: {
      handler() {
        this.getAvailableTeam();
      },
    },
    expectedDurationBasedOnLineItems: {
      handler(val) {
        this.workOrder.duration = Number(this.workOrder.additionalDuration) + val;
      },
    },
    primaryTech: {
      handler() {
        this.updateAction();
      },
    },
    secondaryTech: {
      handler() {
        this.updateAction();
      },
    },
    'workOrder.duration': {
      handler() {
        this.dateChanged(this.workOrder.date);
      },
    },
  },
  mounted() {
    this.pageActionsSnapshotId = `${this.isNew ? uuidv4() : this.workOrderId}-${this.loggedInUser?.employee?.id}`;
    this.workOrder.isEmergency = this.loggedInUserIsTech;
    this.workOrder.isOnCall = this.loggedInUserIsTech;
    this.observeCustomer(this.customerId);
    this.dateChanged(this.workOrder.date);
  },
  async destroyed() {
    this.pingFunction.cancel();
    this.countDown.cancel();
    this.pingCancelled = true;
    await this.removePageAction(this.pageActionsSnapshotId);
  },
  methods: {
    ...mapActions('centralScheduler', ['loadEvents']),
    ezCareChanged() {
      if (!this.newJobSiteEzCare.ezCare) {
        this.newJobSiteEzCare.ezCareStartDate = null;
      }
    },
    setSuggestedStartDate() {
      const suggestedDate = this.getSuggestedStartDate();
      this.workOrder.date = suggestedDate ?? this.workOrder.date;
      this.dateChanged(this.workOrder.date);
    },
    fillInSuggestedStartDates() {
      this.suggestedStartDates = [];
      this.suggestionIndex = 0;

      if (!this.workOrder.date || !this.loggedInUserIsTech) {
        return;
      }
      const conflictsWithEvent = checkDate =>
        this.activeEvents.find(it =>
          dateServiceShared.hasTimeConflict(
            checkDate.toDate(),
            checkDate.add(this.workOrder.duration, 'hour').toDate(),
            it.startTime,
            it.endTime
          )
        );
      this.loggedInUserWorkDay?.timeSlots.forEach(slot => {
        let slotStart = dayjs(slot.start).minute(Math.ceil(slot.start.minute() / 5) * 5);
        let done = false;
        while (!done) {
          if (slotStart.isBefore(dayjs()) || conflictsWithEvent(slotStart)) {
            slotStart = slotStart.add(1, 'hour');
          } else if (slotStart.isBefore(slot.end)) {
            this.suggestedStartDates.push(slotStart.toDate());
            slotStart = slotStart.add(1, 'hour');
          } else {
            done = true;
          }
        }
      });
    },
    getSuggestedStartDate() {
      if (!this.loggedInUserIsTech) {
        return null;
      }

      if (isEmpty(this.suggestedStartDates)) {
        this.fillInSuggestedStartDates();
      }

      const date = this.suggestedStartDates[this.suggestionIndex % this.suggestedStartDates.length];
      this.suggestionIndex += 1;

      if (!date) {
        this.$buefy.notification.open({
          message: 'Unable to find available time.',
          type: 'is-warning',
          indefinite: true,
          closable: true,
        });
      }

      return date;
    },
    goToOriginalWorkOrder() {
      if (this.originalWorkOrder.id) {
        const woPage = this.$router.resolve({
          name: 'manage-work-order',
          params: { workOrderId: this.originalWorkOrder.id },
        });
        window.open(woPage.href, '_blank');
      }
    },
    jobSiteChanged(e) {
      if (!e) {
        this.workOrder.jobSite = {};
        return;
      }
      this.workOrder.jobSite = e;
    },
    getTaxRate(address) {
      if (this.isNew && address) {
        this.loadingTaxInfo = true;
        TaxService.getTaxRate(address)
          .then(r => {
            this.workOrder.taxJurisdictionCode = r.taxJurisdictionCode ?? '';
            this.taxRatePercentage = r.taxRatePercentage ?? 0;
            this.workOrder.isTaxOverride = r.isJurisdictionOverride;
          })
          .catch(e => {
            this.workOrder.taxJurisdictionCode = '';
            this.taxRatePercentage = 0;
            this.$buefy.notification.open({
              message: `Failed to get tax rate for address ${address}, ${e.message}`,
              type: 'is-danger',
            });
          })
          .finally(() => {
            this.loadingTaxInfo = false;
          });
      }
    },
    namePostfixes(tech) {
      const postfixes = [tech?.manager ? 'Mgr' : null, tech?.isForeman ? 'Fman' : null].filter(it => it);
      return postfixes.length > 0 ? ` (${postfixes.join(', ')})` : '';
    },
    onSelectedServiceZone(sz) {
      this.workOrder.serviceZone = sz;
      this.workOrder.serviceZoneOverride = Number(sz.duration ?? 0);
    },
    confirmApproval() {
      this.isApprovalActive = false;
      this.confirm();
    },
    getPageActionsSnapshot() {
      return {
        ...this.buildBasicActions(),
        workorderId: this.workorderId || null,
        editedBy: this.loggedInUserName,
        assignedTechs: [this.primaryTech, this.secondaryTech].filter(it => it),
        startDate: this.workOrder?.startDate,
        endDate: this.workOrder?.endDate,
      };
    },
    searchTechsBySkills(rows) {
      return rows.filter(
        row =>
          row?.skillIds?.some(s => s === this.workOrder?.catalogTypeId) &&
          !!row?.companies?.find(it => it.code === this.workOrder?.company)
      );
    },
    assignToPrimaryTech(row) {
      this.primaryTech = this.primaryTechs?.find(tech => tech.id === row.id)?.id;
    },
    assignToSecondaryTech(row) {
      this.secondaryTech = this.secondaryTechs?.find(tech => tech.id === row.id);
    },
    dateChanged(value) {
      if (!value || !this.workOrder.startDate || !dayjs(value).isSame(dayjs(this.workOrder.startDate), 'day')) {
        this.fillInSuggestedStartDates();
      }
      this.workOrder.startDate = value;
      this.workOrder.endDate = value
        ? new Date(value.getTime() + Number(this.workOrder.duration) * 60 * 60 * 1000)
        : null;
      this.workOrder = { ...this.workOrder };
      this.allTechs = [...this.allTechs];
      this.updateActionAndRefreshConflicts();
      this.handleDynamicProsLoads(this.workOrder.startDate, this.workOrder.endDate, this.workOrderId);
      if (this.loggedInUserIsTech) {
        this.loadEvents({
          startDate: first(this.loggedInUserWorkDay?.timeSlots)?.start?.toDate(),
          endDate: last(this.loggedInUserWorkDay?.timeSlots)?.end?.toDate(),
          employeeId: 'YDS05edt24OkMh81yQiV' ?? this.loggedInUser.employee.id,
        });
      }
    },
    updateActionAndRefreshConflicts() {
      this.updateAction();
      this.handleConflictingWorkorderBookingsLoad(
        this.workOrder.startDate,
        this.workOrder.endDate,
        this.pageActionsSnapshotId
      );
    },
    async updateAction() {
      if (this.pingCancelled) {
        return;
      }
      this.pingFunction();
      await this.upsertPageAction(this.pageActionsSnapshotId, this.getPageActionsSnapshot());
    },
    getAvailableTeam() {
      if (this.employeesUnsub) {
        this.employeesUnsub();
      }

      this.employeesUnsub = EmployeesService.dbRef
        .where('workflowFunction.name', '==', 'Service Tech')
        .where('status', '==', 'active')
        .orderBy('lastName')
        .orderBy('firstName')
        .onSnapshot(
          docs => {
            this.allTechs = docs.docs.map(it => ({
              id: it.id,
              ...pick(
                it.data(),
                'firstName',
                'lastName',
                'email',
                'skillIds',
                'schedule',
                'profileImgPath',
                'profileImgUrl',
                'code',
                'productAreas'
              ),
              manager: String(it.data().manager) === 'true',
              isForeman: it.data().isForeman,
            }));
            this.confirmAssignments();
          },
          e =>
            this.$buefy.notification.open({
              message: `Failed to pull an available team, ${e.message}`,
              type: 'is-danger',
            })
        );
    },
    confirmAssignments() {
      const assignedPrimary = this.availableTechs?.find(
        it => it.id === this.primaryTech || it.id === this.workOrder.assignedTechs?.find(it => it.isPrimary)?.id
      );
      const assignedSecondary = this.availableTechs?.find(
        it => it.id === this.secondaryTech || it.id === this.workOrder.assignedTechs?.find(it => !it.isPrimary)?.id
      );
      this.primaryTech = assignedPrimary?.id;
      this.secondaryTech = assignedSecondary?.id;
      if (!!this.primaryTech && !assignedPrimary?.id) {
        this.$buefy.notification.open({
          message:
            'Primary technician that was supposed to be assigned to the WO was assigned somewhere else. Please reassign!',
          type: 'is-warning',
          closable: true,
          indefinite: true,
          hasIcon: true,
          active: true,
        });
        this.primaryTech = null;
      }
      if (!!this.secondaryTech && !assignedSecondary?.id) {
        this.$buefy.notification.open({
          message:
            'Secondary technician that was supposed to be assigned to the WO was assigned somewhere else. Please reassign!',
          type: 'is-warning',
          closable: true,
          indefinite: true,
          hasIcon: true,
          active: true,
        });
        this.secondaryTech = null;
      }
    },
    getTechs() {
      const [primary, secondary] = map(
        [
          { selectedKey: 'primaryTech', valuesKey: 'primaryTechs' },
          { selectedKey: 'secondaryTech', valuesKey: 'secondaryTechs' },
        ],
        config => {
          return this[config.selectedKey]
            ? pick(
                this.availableTechsMap[this[config.selectedKey]],
                'id',
                'email',
                'isPrimary',
                'firstName',
                'lastName'
              )
            : null;
        }
      );
      return {
        primary: { ...primary, isPrimary: true },
        secondary: { ...secondary, isPrimary: false },
      };
    },
    save(applyExistingStatus, applyExistingState) {
      const errorMessage =
        this.workOrder.duration < 0 ? 'The order duration is negative. Make sure to fix that before proceeding!' : null;

      if (errorMessage) {
        this.$buefy.dialog.confirm({
          message: errorMessage,
          closeOnConfirm: true,
          canCancel: false,
          type: 'is-danger',
        });
        return;
      }

      this.saving = true;
      const { primary, secondary } = this.getTechs();
      const assignedTechs = [primary, secondary].filter(it => it.id);
      const workOrderToSave = {
        isStartedAheadOfTime: false,
        ...this.workOrder,
        assignedTechs,
        techProjectId: this.techProjectIdOverride || this.techProjectId,
        jobInfo: this.workOrder.jobInfo?.trim(),
        techNotes: this.workOrder.techNotes?.trim(),
        assignedTechsEmails: assignedTechs?.map(it => it?.email) || [],
        date: this.workOrder.date,
        startDate: this.workOrder.startDate,
        endDate: this.workOrder.endDate,
        status: applyExistingStatus ? this.workOrder.status : !isEmpty(assignedTechs) ? 'assigned' : 'new',
        state: applyExistingState ? this.workOrder.state : WorkOrderService.WORK_ORDER_STATES.START,
        type: this.workOrderTypes.find(it => it.id === this.workOrder.catalogTypeId)?.name,
        approvalHash: this.approvalHash,
        techsWithOvertime: this.techsWithOvertime,
        [this.isNew ? 'createdBy' : 'updatedBy']: this.loggedInUser.employee.id,
      };

      workOrderToSave.statusTransitionHistory = [
        ...(workOrderToSave.statusTransitionHistory ?? []),
        this.existingWorkOrder?.status === workOrderToSave.status
          ? null
          : {
              user: {
                id: this.loggedInUser?.employee?.id,
                name: [this.loggedInLastName, this.loggedInFirstName].filter(identity).join(' '),
              },
              value: workOrderToSave.status,
              date: new Date(),
            },
      ].filter(identity);
      workOrderToSave.stateTransitionHistory = [
        ...(workOrderToSave.stateTransitionHistory ?? []),
        this.existingWorkOrder?.state === workOrderToSave.state
          ? null
          : {
              user: {
                id: this.loggedInUser?.employee?.id,
                name: [this.loggedInLastName, this.loggedInFirstName].filter(identity).join(' '),
              },
              value: workOrderToSave.state,
              date: new Date(),
            },
      ].filter(identity);

      (this.isNew ? WorkOrderService.create(workOrderToSave) : WorkOrderService.update(workOrderToSave))
        .then(async () => {
          await this.navigate();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    confirm() {
      if (!this.workOrder.jobSite.id) {
        this.$buefy.notification.open({
          message: 'Please select a job site before saving the work order.',
          type: 'is-danger',
        });
        return;
      }

      if (!this.primaryTech && !this.secondaryTech) {
        this.$buefy.dialog.confirm({
          message: 'The WO does not have any assigned technicians. Do you want to continue?',
          onConfirm: () => this.save(),
        });
      } else if (!this.workOrder.approvedBy && this.requiresApproval) {
        this.isApprovalActive = true;
      } else {
        this.save();
      }
    },
    getStartDate() {
      const date = new Date();
      //set minutes to the nearest increment of 5
      date.setHours(date.getHours() + 1, Math.ceil(date.getMinutes() / 5) * 5);
      return date;
    },
    async addNewJobSite(e) {
      const jobSite = {
        ...e,
        customerId: this.customerId,
      };

      const updatedJobSite = await createJobSite(jobSite);
      await this.$asyncComputed.jobSites.update();
      this.workOrder.jobSite = updatedJobSite;
      this.selectedJobSiteAddress = updatedJobSite.address;
    },
    addNewAuthContact() {
      this.addingAuthContact = true;
      this.customer.contacts = [...this.customer.contacts, this.newAuthContact];
      CustomerService.update(this.customer.id, this.customer)
        .then(() => {
          this.isAuthContactAddNewActive = false;
          this.newAuthContact = {
            name: null,
            phone: null,
            email: null,
          };
        })
        .catch(e =>
          this.$buefy.notification.open({
            message: `Failed to add auth contact, ${e.message}`,
            type: 'is-danger',
          })
        )
        .finally(() => {
          this.addingAuthContact = false;
        });
    },
    decline({ reason }) {
      this.workOrder.reasonForDecline = reason;
      this.workOrder.status = WorkOrderService.WORK_ORDER_STATUS.DECLINE_ON_BOOKING;
      this.workOrder.state = this.workOrderStates.FINISHED;
      this.workOrder.finishedAt = new Date();
      return this.save(true, true);
    },
    onProductChange(products) {
      this.workOrder.lineItems = [...products];
      this.workOrder.productTimeOverride = this.productDuration;
    },
    async removeLineItem(idx, item) {
      if (!item.id) {
        this.workOrder.lineItems.splice(idx, 1);
        return;
      }

      const { result } = await this.$buefy.dialog.confirm({
        message: 'Are you sure?',
        closeOnConfirm: true,
      });

      if (result) {
        this.workOrder.lineItems.splice(idx, 1);
      }
    },
    async cancel() {
      this.$buefy.dialog.confirm({
        message: 'All the work order modifications will be lost! Do you want to continue?',
        onConfirm: async () => {
          await this.navigate();
        },
      });
    },
    async navigate() {
      this.isNew ? await this.$router.push({ name: 'customers' }) : await this.$router.push({ name: 'work-orders' });
    },
    calculateWorkOrderDuration() {
      this.workOrder.duration =
        Number(this.workOrder.additionalDuration) +
        Number(this.workOrder.serviceZoneOverride) +
        Number(this.workOrder.productTimeOverride);
    },
    confirmDeleteWorkOrder() {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this workorder?',
        onConfirm: () => this.deleteWorkOrder(),
      });
    },
    async deleteWorkOrder() {
      try {
        await WorkOrderService.softDelete(this.workOrder.id);
        this.$buefy.notification.open({
          message: `Work Order Successfully deleted.`,
          type: 'is-success',
        });
        this.$router.push({ name: 'work-orders' });
      } catch (e) {
        this.$buefy.notification.open({
          message: `Failed to delete the workorder, ${e.message}`,
          type: 'is-danger',
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
#customer-booking {
  max-width: 1600px;

  .created-by {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .collapse {
    visibility: inherit !important;
  }

  .availability {
    position: relative;
    padding-top: 24px;
    overflow: hidden;
    height: 500px;
    z-index: 500;
  }

  .customer-field {
    .field-name {
      font-weight: bold;
      margin-right: 1em;
    }
  }

  .customer {
    .card {
      min-width: 400px;
      max-width: 600px;
    }
  }
}
</style>
