<template>
  <div id="jobsites-page">
    <div class="header-container">
      <div class="header">Jobsites</div>
    </div>
    <search-list
      index-name="job-sites"
      :facets="facets"
      :search-function="searchFunction"
      :transform="transform"
      :is-reportable="true"
    >
      <template #results="{ items }">
        <table class="table is-striped">
          <thead>
            <tr>
              <th>Street</th>
              <th>City</th>
              <th>State</th>
              <th>Zip Code</th>
              <th>Ez Care</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.objectID">
              <td>
                <a @click="onRowClick(item)" class="has-text-link">
                  <ais-highlight attribute="street" :hit="item"></ais-highlight>
                </a>
              </td>
              <td>
                <ais-highlight attribute="city" :hit="item"></ais-highlight>
              </td>
              <td>
                <ais-highlight attribute="state" :hit="item"></ais-highlight>
              </td>
              <td>
                <ais-highlight attribute="zipCode" :hit="item"></ais-highlight>
              </td>
              <td>
                <b-icon :icon="ezCareIcon(item.ezCare)"></b-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </search-list>
  </div>
</template>

<script>
import SearchList from '@/components/list/searchList.vue';

export default {
  name: 'JobsiteList',
  components: {
    SearchList,
  },
  data() {
    return {
      facets: [
        { facet: 'city', displayName: 'City' },
        { facet: 'state', displayName: 'State' },
        { facet: 'zipCode', displayName: 'Zip Code' },
        { facet: 'ezCare', displayName: 'Ez Care' },
      ],
      columns: [
        { field: 'street', label: 'Street' },
        { field: 'city', label: 'City' },
        { field: 'state', label: 'State' },
        { field: 'zipCode', label: 'Zip Code' },
        { field: 'ezCare', label: 'Ez Care' },
      ],
    };
  },
  methods: {
    ezCareIcon(ezCare) {
      return !ezCare || ezCare === '' || ezCare === 'none' || ezCare === 'None' ? 'times' : 'check';
    },
    transform(items) {
      return items.map(item => ({
        ...item,
        street: item.street || 'N/A',
        city: item.city || 'N/A',
        state: item.state || 'N/A',
        zipCode: item.zipCode || 'N/A',
        ezCare: item.ezCare,
      }));
    },
    searchFunction(helper) {
      helper.search();
    },
    onRowClick(row) {
      this.$router.push({
        name: 'jobsite-details',
        params: { id: row.objectID },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table-content {
  margin-top: 1rem;
}
#jobsites-page {
  max-width: 1600px;
}

.has-text-link {
  color: #3273dc;
}

.jobsite-list {
  margin-top: 1rem;
}
</style>
