import { getApi } from '@/service/Api';

import { JobSite } from '@/types/JobSite';
import { PaginatedResponse } from '@/types/Pagination';

interface CustomerChanged {
  id: string;
  customerId: string;
}

export async function getByCustomerId(customerId: string): Promise<JobSite[]> {
  const api = getApi();

  const jobSites = await api.get<JobSite[]>('/v3/job-sites', {
    params: { customerId },
  });
  return jobSites.data;
}

export async function getById(id: string): Promise<JobSite> {
  const api = getApi();

  const jobSite = await api.get<JobSite>(`/v3/job-sites/${id}`);
  return jobSite.data;
}

export async function list(jobSite: JobSite): Promise<PaginatedResponse<JobSite>> {
  const api = getApi();

  const jobSites = await api.get<PaginatedResponse<JobSite>>('/v3/job-sites', {
    params: jobSite,
  });
  return jobSites.data;
}

export async function create(jobSite: JobSite): Promise<JobSite> {
  const api = getApi();

  if (!jobSite.street2) {
    jobSite.street2 = '';
  }
  const response = await api.post<JobSite>('/v3/job-sites', jobSite);
  return response.data;
}

export async function remove(id: string): Promise<void> {
  const api = getApi();
  await api.delete(`/v3/job-sites/${id}`);
}

export async function update(id: string, jobSite: JobSite): Promise<JobSite> {
  const api = getApi();
  const response = await api.put<JobSite>(`/v3/job-sites/${id}`, jobSite);
  return response.data;
}

export async function changeCustomer(id: string, customerId: string): Promise<CustomerChanged> {
  const api = getApi();
  const res = await api.post(`/v3/job-sites/${id}/change-customer`, { customerId });
  return res.data;
}
